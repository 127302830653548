/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  width: 285px;
  border: 1px solid $color-orange;
  color: $color-gray;
  font-size: rem(16px);
  line-height: 1.5;
  letter-spacing: 0.08em;
  padding: 18px 30px;
  cursor: pointer;
  text-align: center;
  background-color: $color-white;
  position: relative;
  transition: all 0.3s ease-in-out;

  // &::after {
  //   content: "";
  //   display: block;
  //   width: 8px;
  //   height: 8px;
  //   border-top: 1px solid $color-gray;
  //   border-right: 1px solid $color-gray;
  //   transform: rotate(45deg);
  //   position: absolute;
  //   top: 50%;
  //   right: 15px;
  //   transition: all 0.3s ease-in-out;
  // }

  @include view-at(tab) {
    width: 80%;
    padding: 12px 30px;
  }

  &:hover {
    opacity: 1;
    background: $color-orange;
    color: $color-white;

    // &::after {
    //   border-color: $color-white;
    //   right: 12px;
    // }
  }

  span{
    font-size: rem(24px);
    padding-right: 0.5em;
  }

  &--primary {
    border-color: transparent;
    background: $color-orange;
    color: $color-white;

    &::after {
      content: none;
    }

    &:hover {
      border-color: $color-orange;
      background: $color-white;
      color: $color-orange;
    }
  }

  &--white {
    border: none;
    color: $color-orange;

    &::after {
      border-color: $color-orange;
    }

    &:hover {
      background: $color-orange;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray;
    border-color: $color-gray;
    color: $color-white;

    &::after {
      content: none;
    }
  }
}
