/* --------------------------
フッター
-------------------------- */

.p-footer {
	background: $color-light-gray;
	padding-bottom: 25px;


	&-container{
		border-top:1px solid $color-orange;
		width:90%;
		margin: 0 auto;
		padding: 50px 0;

		@include view-at(tab){
			padding: 30px 0;
		}
	}

	&-contents{
		display: flex;
		justify-content: space-between;
		

		@include view-at(tab){
			flex-direction: column;
			align-items: center;
		}
	}

	&-logo{
		flex-shrink: 0;

		@include view-at(tab){
			margin-bottom: 30px;
			width: 100%;
		}

		a{
			display: block;

			@include view-at(tab){
				text-align: center;
			}
			img{
				height: 35px;
				width: auto;
			}
		}
	}
	&-copyright{
		
		text-align: center;

	}
}
