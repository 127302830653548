/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-light-gray;

  &--border {
    border: 1px solid $color-orange;
    background-color: $color-white;
  }

  &-contents {
    padding: 60px;

    @include view-at(sp) {
      padding: 20px;
    }

    &--narrow {
      padding: 15px;
    }
  }

  &-h{
    font-size: rem(20px);
    line-height: 1.5;
    margin-bottom: 15px;
  }
}
