.details{
    &-list{
        display: flex;
        justify-content: center;
        margin: -15px;

        @include view-at(tab){
            flex-wrap: wrap;
            margin: -10px;
        }

        &-item{
            padding:15px;
            width: 25%;
            height: 110px;

            @include view-at(tab){
                width: 50%;
                padding:10px;
                
            }

            @include view-at(sp){
                width: 100%;
                height: auto;
                
            }
        }

    }
    &-contents{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        background: $color-white;
        border: 1px solid $color-orange;
        border-radius: 100px;
        padding:20px 10px;
        line-height: 1.2;

        @include view-at(sp){
            padding:15px 10px;
        }
    }
}