/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px 0 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  z-index: 10001;

  &-inner {
    max-width: 1680px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include view-at(tab) {
      max-width: 100%;
    }
  }

  &-logo {
    font-size: 0;
    a {
      display: block;

      img {
        width: auto;
        height: 35px;

        @include view-at(sp) {
          height: 25px;
        }
      }
    }
  }
}
