.p-faq{
	margin-bottom: 10px;

	a{
		display: block;
		padding-right:30px;
		background-color: $color-gray;
		color:$color-white;
		font-size: rem(18px);
		letter-spacing: 0.04em;
		line-height: 1.5;
		position: relative;

		&::after{
			content: '';
			display: block;
			position: absolute;
			top:calc(50% - 5px);
			right:15px;
			border-bottom: solid 2px $color-white;
			border-right: solid 2px $color-white;
			width: 10px;
			height: 10px;
			transform: rotate(45deg);
		}
	}

	&-question{
		padding:10px 15px;

		&-number{
			background: $color-orange;
			padding:10px;
			color:$color-white;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&-header{
			display: flex;
		}
		

		
	}

	&-contents{
		display: none;
		margin: 10px 0;
		border: 1px solid $color-black;
		padding: 30px;

		@include view-at(sp){
			padding: 20px;
		}
		

		&.visible{
			display: block;
		}
	}
}