/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  @include view-at(tab) {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $color-light-gray;
    padding-top: 69px;

    &.visible {
      display: block;
    }
  }
  &-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: -15px;

    @include view-at(tab) {
      flex-direction: column;
      margin: 0;
      padding: 0 20px;
    }

    &-item {
      padding: 15px;
      width: 100%;
      text-align: center;

      @include view-at(tab) {
        width: 100%;
      }

      &.current {
        a {
          opacity: 1;
          pointer-events: none;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 4px;
            background: $color-orange;
            opacity: 1;
            visibility: visible;
          }
        }

        @include view-at(sp){
          a{
            border: 1px solid $color-gray;
              &::after{
                background: none;
              }
          }
          
        }
      }

      &--en {
        font-size: rem(18px);
        font-weight: $bold;
        line-height: 1.6;
        letter-spacing: 0.1rem;
        text-indent: 0.2em;

       
      }

      a {
        display: block;
        font-size: rem(12px);
        font-weight: $bold;
        letter-spacing: 0.1em;
        line-height: 1.2;
        padding: 10px;
        position: relative;

        @include view-at(tab) {
          font-size: rem(18px);
          color: $color-gray;
          padding: 15px 20px;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 2px;
          left: 0;
          width: 100%;
          height: 4px;
          background: $color-orange;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s;
          
        }

        &:hover {
          &::after {
            bottom: -10px;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &-inner{
    @include view-at(sp){
      display: flex;
      justify-content: center;
      align-items: center;

      .p-gnav-list-item--en{
        padding-right: 20px;
      }
    }
  }
}
