/* --------------------------
テーブル
-------------------------- */

.c-table {
  border-top: 1px solid $color-gray;
  width: 100%;
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);



  tbody {
    tr {
      border-bottom: 1px solid $color-gray;

      @include view-at(sp) {
        display: block;
        border-bottom: 0;
      }
    }
    th {
      padding: 15px;
      line-height: 1.5;
      font-weight: normal;
      width: 150px;
      font-weight: $bold;
      text-align: right;

      @include view-at(sp) {
        display: block;
        width: 100%;
        background-color: $color-gray;
        text-align: center;
        color:$color-white;
      }
    }
    td {
      padding: 15px;
      line-height: 1.5;

      @include view-at(sp) {
        display: block;
        width: 100%;
        border-bottom: 1px solid $color-gray;
      }
    }
  }

  &--narrow {
    border-color: $color-gray;
    tbody {
      tr {
        border-color: $color-gray;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: $bold;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }
}
