.top{
  &-keyvisual{
    background-image: url(../images/top/mv.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $color-light-gray;
    padding:150px 0;
    position: relative;
    z-index: 1;

    &::before{
      content: '';
      display: block;
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(../images/top/shadow.png);
      background-position: left top;
      background-repeat: repeat;
    }

    @include view-at(sp){
      padding: 50px 0;
    }

    &-contents{
      position: relative;
      z-index: 2;
    }

    &-catchcopy{
      font-size: rem(40px);
      line-height: 1.5;
      font-weight: $bold;
      letter-spacing: 0.1rem;
      text-align: center;
      
      

      @include view-at(sp){
        font-size: rem(32px);
      }
    }
  }

  &-works{
    &-list{
      border:1px solid $color-orange;
      background: $color-white;
    }

    &-thumbnail{
      img{
        width: 100%;
      }
    }

    // &-contents{
    //   background: $color-white;
      
    // }

    &-arrow{
      position: absolute;
      top:50%;
      width: 25px;
      height: 25px;
      z-index: 100;

      &--prev{
        left: -5%;
        border-bottom: solid 4px $color-black;
        border-left: solid 4px $color-black;
        transform: rotate(45deg);

        @include view-at(tab){
          left: 0;
        }
      }

      &--next{
        right: -5%;
        border-top: solid 4px $color-black;
        border-right: solid 4px $color-black;
        transform: rotate(45deg);

        @include view-at(tab){
          right: 0;
        }
      }

    }
  }

    
}