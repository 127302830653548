/* --------------------------
color
-------------------------- */
$color-white: #fff;
$color-black: #333;
$color-gray: #707070;
$color-light-gray: #f7f7f7;

$color-orange: #ee5522;

/* --------------------------
spacing
-------------------------- */
$space: 100px, 80px, 50px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 50px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 24px, 20px, 18px, 16px, 15px, 12px;
$line-height: 44px, 26px, 24px, 22px, 22px, 18px;

$font-size-sp: 24px, 20px, 18px, 16px, 14px, 12px;
$line-height-sp: 44px, 26px, 22px, 20px, 22px, 18px;

$bold: 500;
$normal: 300;

/* --------------------------
contents width
-------------------------- */
$contents-width: 1040px;
$contents-narrow-width: 940px;
$contents-padding: 20px;
$contents-padding-sp: 25px;

/* --------------------------
z-index order
-------------------------- */
$z: (contents, arrow, gnav, leftbar, header, footer, modal);
