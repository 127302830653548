.loading {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-white;
  z-index: 100001;

  &-image {
    max-width: 500px;
    padding: 30px;
    
    @include view-at(sp) {
      max-width: 350px;
    }
  }
}

body#top {
  visibility: hidden;

  &.js-loaded{
    .p-gnav-list-item.current a::after {
      visibility: visible;
    }

  }

  .p-gnav-list-item.current a::after {
    visibility: hidden;
  }
}

body {
  font-family: "ヒラギノ角ゴ ProN W3", "HiraKakuProN-W3", "ヒラギノ角ゴ Pro W3",
    "HiraKakuPro-W3", "Noto Sans JP", Helvetica, Helvetica Neue, Arial,
    "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS Pgothic", "Osaka", sans-serif,
    Verdana;
  font-weight: 300;
  color: $color-black;
}



a {
  color: $color-black;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}

p {
  text-align: justify;
  word-wrap: break-word;
  margin-bottom: 1em;
  font-size: nth($font-size, 5);
  line-height: 2;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 84px;

  @include view-at(tab) {
    padding-top: 59px;
  }

  @include view-at(sp) {
    padding-top: 50px;
  }
}
