/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
  color: $color-gray;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}

.u-text-x-large {
  font-size: nth($font-size, 1);
  line-height: nth($line-height, 1);
  letter-spacing: 0.06em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
    line-height: nth($line-height-sp, 1);
  }
}

.u-text-large {
  font-size: rem(18px);
  line-height: 1.5;
  

  @include view-at(sp) {
    font-size: rem(20px);
    
  }
}

.u-text-bit-large {
  font-size: nth($font-size, 3);
  line-height: nth($line-height, 3);
  letter-spacing: 0.03em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
  }
}

.u-text-default {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.u-text-small {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}

.u-text-x-small {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}
