/* --------------------------
Fナビ
-------------------------- */

.p-fnav{
	width: 100%;

	&-list{
		display: flex;
		justify-content: flex-end;
		margin: 0 -30px;

		&:first-child{
			margin-bottom: 30px;

			@include view-at(sp){
				margin-bottom: 0;
			}
		}

		@include view-at(sp){
			flex-wrap: wrap;
			justify-content: flex-start;
			margin: 0 -10px;
		}

		&-item{
			padding: 0 30px;
			text-align: center;

			@include view-at(sp){
				width: 100%;
				padding: 10px;
				text-align: center;
			}
			a{
				display: block;
				text-align: center;
				letter-spacing: .1em;
			}
		}
	}

	&-en{
		font-size: rem(18px);
		line-height: 1.6;

		@include view-at(sp){
			font-size: rem(16px);
		}
	}

	&-ja{
		font-size: rem(12px);		
	}

	&-inner{
		@include view-at(sp){
			display: flex;
			justify-content: center;
			align-items: center;

			.p-fnav-en{
				padding-right: 20px;
			}

		}
		
	}
}