.p-cta{
	background:$color-light-gray;
	color: $color-gray;	
	padding: 80px 0;

	@include view-at(sp) {
		padding: 40px 0;
	}

	&-h1-heading{
		color: $color-gray;
		text-align: center;
		margin-bottom:50px;

		@include view-at(sp){
			margin-bottom: 25px;
		}

		&--en{
			font-size: rem(40px);
			line-height: 1.5;
			letter-spacing: 0.1rem;
			font-weight: $bold;
			display: block;

			@include view-at(sp){
        		font-size: 31px;
      		}
		}

		&--ja{
			display: block;
			margin-top: 10px;
			line-height: 1.4;		

			@include view-at(sp){
        		font-size: 16px;
        		margin-top: 5px;
      		}
		}

	}
	&-button {
		color: $color-gray;
		background: $color-white;
		display: inline-block;
		padding: 30px 0;
		margin: 15px 0 0;
		text-align: center;
		max-width: 500px;
		width:100%;
		position: relative;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid $color-orange;
		outline: 1px solid;
		outline-color: rgba(238, 85, 34, 0.5);
		outline-offset: 0px;
		text-shadow: none;
		transition: all 1.2s cubic-bezier(0.2, 1, 0.2, 1);

		@include view-at(sp) {
			padding: 20px 0;
		}

		&:hover {
			border-color: $color-orange;
			box-shadow: inset 0 0 20px rgba(238, 85, 34, 0.5), 0 0 20px rgba(238, 85, 34, 0.2);
			outline-color: transparent;
			outline-offset: 12px;
			// text-shadow: 2px 2px 3px $color-orange;
		}

		span{
    		font-size: rem(24px);
    		padding-right: 0.5em;
  		}
	}

}

