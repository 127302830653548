/* --------------------------
見出し
-------------------------- */

.c-h1 {
  background-color: $color-gray;
  background-image: url(../images/common/hero.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/common/shadow.png);
    background-position: left top;
    background-repeat: repeat;
    z-index: 2;
  }

  &-heading {
    color: $color-white;
    text-align: center;
    padding: 50px 0;
    position: relative;
    z-index: 3;

    &--black {
      color: $color-black;
    }

    &--en {
      font-size: rem(42px);
      line-height: 1.3;
      letter-spacing: 0.1rem;
      display: block;

      @include view-at(sp) {
        font-size: rem(28px);
      }
    }

    &--ja {
      font-size: rem(18px);
      margin-top: 10px;
      line-height: 1.4;
      font-weight: $bold;
      display: block;

      @include view-at(sp) {
        font-size: rem(16px);
        margin-top: 5px;
      }
    }
  }
}

.c-h2 {
  font-size: rem(24px);
  font-weight: $bold;
  line-height: 1.3;
  letter-spacing: 0.1rem;

  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 4px;
    background: $color-orange;
    margin: 35px 0 35px;

    @include view-at(sp) {
      margin: 20px 0;
    }
  }

  @include view-at(sp) {
    font-size: rem(18px);
  }
}

.c-h3 {
  font-size: rem(18px);
  line-height: 1.2;
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 30px;

  @include view-at(sp) {
    font-size: rem(20px);
    margin-bottom: 15px;
  }
}

.c-h4 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 10px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.c-h5 {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}

.c-h6 {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}
