.service {
  &-anchor {
    &-list {
      display: flex;
      justify-content: space-between;

      @include view-at(sp) {
        flex-direction: column;
      }

      &-item {
        width: 100%;
        text-align: center;

        a {
          display: block;
          text-decoration: none;
          padding: 20px;
          border-bottom: 2px solid $color-gray;
          font-weight: $bold;
          letter-spacing: 0.05em;
          line-height: 1.5;
          position: relative;
          transition: all 0.3s;

          &:hover {
            border-bottom: 2px solid $color-orange;
          }
        }

        &--select {
          a {
            border-bottom: 2px solid $color-orange;
          }
        }
      }
    }
  }

  &-section {
    position: relative;
    height: 400px;

    @include view-at(tab) {
      height: 420px;
    }

    @include view-at(tab) {
      height: 500px;
      margin-bottom: 50px;
    }

    &-header {
      width: calc(100% - 140px);
      height: 400px;
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      @include view-at(tab) {
        width: 100%;
        height: 300px;
      }

      @include view-at(sp) {
        height: 220px;
      }

      &--website {
        background-image: url(../images/service/section-website.jpg);
        background-position: left center;
      }

      &--marketing {
        background-image: url(../images/service/section-marketing.jpg);
      }

      &--consulting {
        background-image: url(../images/service/section-consulting.jpg);
      }
    }

    &-contents {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: -20px;

      @include view-at(tab) {
        flex-wrap: wrap;
      }

      &-wrapper {
        width: calc(100% - 10%);
        position: absolute;
        bottom: 10%;
        right: 0;

        @include view-at(tab) {
          width: 100%;
          padding: 0 40px;
          bottom: 0;
        }

        @include view-at(tab) {
          padding: 0 25px;
        }
      }
    }

    &-title {
      color: $color-white;
      padding: 20px;
    }

    &-intro {
      padding: 20px;
      flex-shrink: 0;
      width: 40%;

      @include view-at(tab) {
        width: 100%;
      }

      &-inner {
        background: rgba(247, 247, 247, 0.8);
        padding: 40px;

        @include view-at(tab) {
          padding: 30px;
        }
      }
    }

    &-list {
      &-item {
        &:nth-child(2n) {
          flex-direction: row-reverse;
        }

        @include view-at(sp) {
          flex-direction: column-reverse;

          &:nth-child(2n) {
            flex-direction: column-reverse;
          }
        }
      }
    }

    &-text {
      padding: 50px;

      @include view-at(tab) {
        padding: 40px;
      }

      @include view-at(sp) {
        padding: 25px;
      }
    }
  }
}
